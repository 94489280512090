/* Base Tag Input Styles */
.react-tagsinput-tag {
    background-color: #3b82f6;
    border-radius: 6px;
    border: none;
    color: #ffffff;
    display: inline-block;
    font-family: 'Inter', 'Segoe UI', sans-serif;
    font-size: 13px;
    font-weight: 500;
    margin: 3px;
    padding: 4px 10px;
    text-transform: capitalize;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
}

.react-tagsinput-tag:hover {
    background-color: #2563eb;
}

.interest .react-tagsinput-tag {
    background-color: #f3f4f6;
    color: #4b5563;
    border: 1px solid #e5e7eb;
}

.interest .react-tagsinput-tag:hover {
    background-color: #e5e7eb;
}

.react-tagsinput {
    border: none;
    padding-left: 0;
    background-color: transparent;
}

.react-tagsinput-input {
    font-family: 'Inter', 'Segoe UI', sans-serif;
    font-size: 13px;
    padding: 4px 0;
    margin-left: 3px;
    color: #4b5563;
}

.react-tagsinput-remove {
    display: none;
}

/* Resume Container Styles */
#printContainer {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    overflow: hidden;
}

/* Section Headers */
h4.text-2xl {
    font-family: 'Inter', 'Segoe UI', sans-serif;
    letter-spacing: 0.025em;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 16px;
}

h4.text-2xl::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40px;
    height: 3px;
    background-color: #3b82f6;
    border-radius: 3px;
}

/* Work Experience Item Styling */
.work-experience-item {
    border-left: 3px solid transparent;
    transition: all 0.2s ease;
    border-radius: 8px;
}

.work-experience-item:hover {
    border-left-color: #3b82f6;
}

/* Education Item Styling */
.education-item {
    transition: all 0.2s ease;
    border-radius: 8px;
}

/* Language Item Styling */
.language-item {
    transition: all 0.2s ease;
    border-radius: 8px;
}

/* Button Styling */
button.bg-blue-500 {
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}

button.bg-blue-500:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button.bg-green-500 {
    transition: all 0.2s ease;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    font-weight: 500;
}

button.bg-green-500:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Contact Info Bar */
.bg-gray-200 {
    background-color: #f9fafb !important;
    border-top: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
}

/* Print Styles */
@media print {
    #printContainer {
        width: '210mm';
        height: 'auto';
        margin: 0;
        padding: 0;
        box-shadow: none;
    }
    
    /* Hide security banner when printing */
    .security-banner {
        display: none !important;
    }
    
    /* General page break rules */
    h4 {
        page-break-after: avoid;
        break-after: avoid-page;
        font-size: 1rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    ul, img, table {
        page-break-inside: avoid;
        break-inside: avoid;
    }
    
    /* Allow page breaks between sections */
    .py-4 {
        page-break-inside: auto;
        break-inside: auto;
    }
    
    /* Ensure each work experience item can break across pages if needed */
    .hover\:bg-gray-100 {
        page-break-inside: auto;
        break-inside: auto;
    }
    
    /* Keep headers with their first paragraph */
    h4 + div {
        page-break-before: avoid;
        break-before: avoid-page;
    }
    
    /* Hide non-printable elements */
    button, .hover\:cursor-pointer {
        display: none !important;
    }
    
    /* Ensure proper margins and layout */
    @page {
        size: A4;
        margin: 8mm;
    }
    
    /* Ensure background colors print */
    * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
    
    /* Print container specific styles */
    .print-container {
        width: 210mm;
        min-height: 297mm;
        padding: 0;
        margin: 0 auto;
    }
    
    /* Reduce spacing in print mode */
    .print\:py-1 {
        padding-top: 0.15rem !important;
        padding-bottom: 0.15rem !important;
    }
    
    .print\:py-2 {
        padding-top: 0.35rem !important;
        padding-bottom: 0.35rem !important;
    }
    
    .print\:px-2 {
        padding-left: 0.35rem !important;
        padding-right: 0.35rem !important;
    }
    
    .print\:px-3 {
        padding-left: 0.5rem !important;
        padding-right: 0.5rem !important;
    }
    
    /* Smaller font sizes for print */
    .print\:text-xs {
        font-size: 0.7rem !important;
        line-height: 1rem !important;
    }
    
    .print\:text-sm {
        font-size: 0.8rem !important;
        line-height: 1.1rem !important;
    }
    
    .print\:text-base {
        font-size: 0.9rem !important;
        line-height: 1.2rem !important;
    }
    
    .print\:text-lg {
        font-size: 1rem !important;
        line-height: 1.3rem !important;
    }
    
    .print\:text-xl {
        font-size: 1.1rem !important;
        line-height: 1.4rem !important;
    }
    
    .print\:text-2xl {
        font-size: 1.2rem !important;
        line-height: 1.5rem !important;
    }
    
    /* Reduce margins between elements */
    .print\:mb-0 {
        margin-bottom: 0 !important;
    }
    
    .print\:mb-1 {
        margin-bottom: 0.15rem !important;
    }
    
    .print\:mb-2 {
        margin-bottom: 0.35rem !important;
    }
    
    .print\:mt-1 {
        margin-top: 0.15rem !important;
    }
    
    .print\:mt-2 {
        margin-top: 0.35rem !important;
    }
    
    /* Specific component styles */
    .work-experience-item {
        break-inside: avoid-page;
        page-break-inside: avoid;
        padding-top: 0.25rem !important;
        padding-bottom: 0.25rem !important;
        margin-bottom: 0.5rem !important;
    }
    
    /* Reduce space between list items */
    .print\:space-y-0 > * + * {
        margin-top: 0 !important;
    }
    
    /* Make input fields more compact */
    .react-edit-text-input {
        font-size: 0.8rem !important;
        padding: 0.1rem 0.2rem !important;
    }
    
    /* Make tags smaller */
    .react-tagsinput-tag {
        font-size: 0.7rem !important;
        padding: 0.1rem 0.3rem !important;
        margin: 0.1rem !important;
    }
    
    .education-item, .language-item {
        break-inside: avoid-page;
        page-break-inside: avoid;
        margin-bottom: 0.5rem;
    }
    
    /* Section styles */
    .section-work-experience, 
    .section-qualification, 
    .section-skills, 
    .section-languages, 
    .section-interests {
        break-before: auto;
        page-break-before: auto;
    }
    
    /* Allow orphans and widows to control page breaks */
    p {
        orphans: 3;
        widows: 3;
    }
}